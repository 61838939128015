import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { SearchField, ButtonWrapper, StyledDataTable } from "./style";
import { Link } from "react-router-dom";
import { encryptVal } from "../../utils/utility";
import Modal from "../Modal";
import rightArrow from "../../assets/images/right-arrow.png";
import moment from "moment";
import { toast } from "react-toastify";
import { DebounceInput } from "react-debounce-input";
import { getAllFolderCount, deleteOrdersData, sendRefundMail, handleChange, searchOrder, getOrdersList } from "../../features/order/orderSlice";
import Pagination from "../CustomPagination";
import { jwtDecode } from "jwt-decode";

const ReactDataTable = ({
  data,
  hideButtonWrapper,
  buttonWrapper,
  customColumns,
  customData,
  customOrdersData,
  hideSelectableRows,
  orderName,
  setPage,
  setLimit,
  selectableRowDisabled,
  hideSearchInput,
}) => {
  const isLoading = useSelector((state) => state?.order?.loading);
  const token = localStorage.getItem("user");
  let userType;
  if (token !== null) {
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    userType = decodedToken?.user?.type;
  }
  hideButtonWrapper = typeof hideButtonWrapper !== "undefined" ? hideButtonWrapper : false;
  buttonWrapper = typeof buttonWrapper === "undefined" ? (
    <ButtonWrapper>
      {userType === "Manager" || userType === "Admin" ? (
        <div className="row">
          <div className="col-sm-6">
            <button
              type="button"
              name="submit"
              value="Delete"
              className="btn blue-btn"
              onClick={() => setShow(true)}
            >
              Delete Order{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
          </div>
        </div>
      ) : null}
    </ButtonWrapper>) : typeof buttonWrapper === "string" && buttonWrapper === "refund" ? (
      <ButtonWrapper>
        {userType === "Manager" || userType === "Admin" ? (
          <div className="row">
            <div className="col-sm-6">
              <button
                type="button"
                name="submit"
                value="Delete"
                className="btn blue-btn"
                onClick={() => setShow(true)}
              >
                Delete Order{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>{" "}
              <button
                type="button"
                name="submit"
                value="invoice"
                className="btn blue-btn"
                onClick={() => handleRefundMail()}
                disabled={isLoading}
              >
                Refunded it{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-sm-6">
              <button
                type="button"
                name="submit"
                value="invoice"
                className="btn blue-btn"
                onClick={() => handleRefundMail()}
                disabled={isLoading}
              >
                Refunded it{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>
            </div>
          </div>
        )}
      </ButtonWrapper>
    ) : (buttonWrapper);
  let filteredArray = [];
  let columns;
  let Data = [];
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState();
  const [show, setShow] = useState(false);
  const SelectedData = useSelector((state) => state?.order?.selectedData);
  const count = useSelector((state) => state?.order?.count);
  const [pageNum, setPageNum] = useState(1);
  const [rowLimit, setRowLimit] = useState(10);
  const [searchText, setSearchText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData([orderId]);
  };
  let [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (SelectedData && SelectedData?.length !== 0) {
      setSelectedData(SelectedData);
    }
  }, [SelectedData]);

  useEffect(() => {
    setCurrentPage(pageNum);
    orderName?.includes("awaitingOrder")
    ? setTotalRows(count?.awaitingOrdersCount)
    : orderName?.includes("awaitingGovt")
    ? setTotalRows(count?.awaitingGovtCount)
    : orderName?.includes("priorityOrder")
    ? setTotalRows(count?.priorityOrdersCount)
    : orderName?.includes("completed")
    ? setTotalRows(count?.completedOrdersCount)
    : orderName?.includes("delete")
    ? setTotalRows(count?.deletedOrdersCount)
    : orderName?.includes("contactCustomer")
    ? setTotalRows(count?.contactCustomerOrdersCount)
    : orderName?.includes("called")
    ? setTotalRows(count?.customerCalledCount)
    : orderName?.includes("new")
    ? setTotalRows(count?.newOrdersCount)
    : orderName?.includes("history")
    ? setTotalRows(count?.allOrdersCount)
    : orderName?.includes("pending")
    ? setTotalRows(count?.pendingOrdersCount)
    : orderName?.includes("refund")
    ? setTotalRows(count?.refundOrdersCount)
    : orderName?.includes("all")
    ? setTotalRows(count?.allOrdersCount)
    : setTotalRows(0);
  }, [pageNum, count, orderName]);

  const handlePageChange = (page) => {
    setPage(page);
    setPageNum(page);
  };

  const handleRowsChange = (limit) => {
    if (limit === "All") {
      setLimit(totalRows);
      setRowLimit(totalRows);
    } else {
      setLimit(limit);
      setRowLimit(limit);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    let searchData = {
      start: pageNum,
      end: rowLimit,
      searchVal: e.target.value,
      folderName: orderName,
    };
    if (e.target.value !== "") {
      console.log("searchData", searchData);
      dispatch(searchOrder(searchData));
    } else {
      dispatch(
        getOrdersList({
          orderName: orderName,
          page: pageNum,
          perPage: rowLimit,
        })
      );
    }
  };

  const handleRefundMail = () => {
    if (
      selectedData !== null &&
      selectedData?.length !== 0 &&
      typeof selectedData !== "undefined"
    ) {
      dispatch(sendRefundMail(selectedData))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(
              getOrdersList({
                orderName: "refundOrder",
                page: pageNum,
                perPage: rowLimit,
              })
            );
          } else {
            if (res.status === 0) {
              toast.error(`${res.message}`, {
                className: "toast-message",
              });
            }
          }
        });
    }
  };
  const deleteOrder = () => {
    let data = {
      orderId: selectedData,
      // permanentDeletedFlag: orderName === "deletedorder" ? true : false,
      updatingStatus: "Deleted",
    };
    dispatch(deleteOrdersData(data))
      .then((res) => {
        handleClose();
        if (res.payload) {
          toast.success(`Order has been deleted successfully`, {
            className: "toast-message",
          });
          dispatch(
            getOrdersList({
              orderName: orderName,
              page: pageNum,
              perPage: rowLimit,
            })
          );
          dispatch(getAllFolderCount());
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          toast.success(`${err.message}`, {
            className: "toast-message",
          });
        }
      });
  };
  data = (typeof data !== 'undefined' && data?.length !== 0 && typeof data?.data !== 'undefined' && data?.data?.length !== 0) ? data?.data : data;
  if (data && !customData && typeof data !== "undefined" && typeof data !== null && data?.length !== 0) {
    Data = data?.map((item, index) => {
      return {
        id: item?.order_id ? item?.order_id : "-",
        status: item?.process_status ? item?.process_status[0].toUpperCase()+item?.process_status.slice(1)  : "-",
        name: `${item?.first_name || ''} ${item?.middle_name || ''} ${item?.last_name || ''}`,
        email: item?.email ? item?.email : "-",
        telephone: item?.phone_number ? item?.phone_number : "-",
        date: item?.created_at ? item?.created_at : "-",
        departureDate: item?.date_of_departure ? item?.date_of_departure : "-",
        refundDate: item?.refund_date ? item?.refund_date : "-",
        processDate: moment(item?.processing_date).utc().format("DD/MM/YYYY"),
        processMonth: item?.process_month,
      };
    });
  } else if (typeof customData !== "undefined" && customData?.length !== 0) {
    Data = customData?.map((item) => {
      return {
        id: item?.id ? item?.id : "-",
        name: item?.name ? item?.name : "-",
        email: item?.email ? item?.email : "-",
        password: item?.password ? item?.password : "-",
        type: item?.type ? item?.type : '',
        memberRole: item?.type ? item?.type : "-",
      };
    });
  } else {
    Data = customOrdersData?.map((item) => {
      return {
        id: item?.order_id ? item?.order_id : "-",
        status: item?.process_status ? item?.process_status : "-",
        name: `${item?.first_name || ''} ${item?.middle_name || ''} ${item?.last_name || ''}`,
        email: item?.email ? item?.email : "-",
        telephone: item?.phone_number ? item?.phone_number : "-",
        date: item?.created_at ? item?.created_at : "-",
        departureDate: item?.date_of_departure ? item?.date_of_departure : "-",
        refundDate: item?.refund_date ? item?.refund_date : "-",
        processDate: moment(item?.processing_date).utc().format("MM/DD/YYYY"),
        processMonth: item?.process_month,
      };
    });
  }
  if (!customColumns) {
    columns = [
      {
        name: "Order Id",
        width: "10rem",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Name",
        width: "17rem",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Email",
        width: "15rem",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Telephone",
        width: "10rem",
        selector: (row) => row.telephone,
        sortable: true,
      },
      {
        name: "Date & Time",
        width: "15rem",
        selector: (row) => row.date
          ? moment(new Date(row.date)).utc().format("MM-DD-YYYY hh:mm:ss")
          : "",
        sortable: true,
      },

      {
        name: "Status",
        width: "8rem",
        selector: (row) => row.status,
        sortable: true,
      },
      {
        name: "Action",
        width: "15rem",
        selector: (row) =>
          Data?.length ? (
            <span>
              <Link
                to={`/admin/view-order/${encryptVal(row?.id)}`}
                style={{
                  border: "1px solid #18f",
                  borderRadius: "5px",
                  padding: " 0 5px",
                  minWidth: "80px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "40px",
                  lineHeight: "40px",
                  color: "#111",
                  margin: "5px",
                  textDecoration: "none",
                }}
                className="blue-border"
              >
                View
              </Link>{" "}
              {userType === "Manager" || userType === "Admin" ? (
                <Link
                  to="#"
                  onClick={() =>
                    handleShow({ id: row?.id, status: row?.status })
                  }
                  style={{
                    textDecoration: "none",
                    border: "1px solid #e92a11",
                    borderRadius: "5px",
                    padding: " 0 5px",
                    minWidth: "80px",
                    display: "inline-block",
                    textAlign: "center",
                    height: "40px",
                    lineHeight: "40px",
                    color: " #e92a11",
                    margin: "5px",
                  }}
                >
                  Delete
                </Link>
              ) : null}
            </span>
          ) : (
            " "
          ),
      },
    ];

    if (typeof orderName !== "undefined" && orderName !== null) {
      if (orderName === "contactCustomerOrder") {
        let FilterColumn = columns.filter((item) => item.name !== "Telephone");
        columns = FilterColumn;
      }
    }
  } else {
    columns = customColumns;
  }

  const CustomMaterialPagination = () => {
    totalRows = typeof totalRows === "undefined" ? 0 : totalRows;
    return (
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalRows}
        pageSize={rowLimit}
        onPageChange={(page) => handlePageChange(page)}
        paginationRowsPerPageOptions={["10", "25", "50", "100", "500", "All"]}
        handleRowsChange={handleRowsChange}
      />
    );
  };

  return (
    <>
      <Modal
        show={show}
        close={handleClose}
        size={"s"}
        confirmAlert={true}
        noEvent={() => handleClose()}
        yesEvent={() => deleteOrder()}
      >
      </Modal>
      {hideSearchInput ? null : (
        <SearchField>
          <DebounceInput
            minLength={0}
            debounceTimeout={500}
            type="text"
            id="search_field"
            name="search_field"
            className="search-bar form-control"
            value={searchText}
            onChange={(e) => handleSearch(e)}
            placeholder="Search"
          />
        </SearchField>
      )}
      <StyledDataTable>
        <DataTable
          className="uspassport-custom-tbl"
          selectableRows={
            hideSelectableRows ? false : Data?.length ? true : false
          }
          columns={columns}
          data={Data?.length ? Data : [{}]}
          progressPending={isLoading}
          fixedHeader={true}
          fixedHeaderScrollHeight="550px"
          pagination={Data?.length && !customOrdersData ? true : false}
          paginationServer
          paginationTotalRows={totalRows}
          
          paginationDefaultPage={currentPage}
          paginationComponentOptions={{ selectAllRowsItem: true }}
          noDataComponent="No records found"
          onSelectedRowsChange={(e) => dispatch(handleChange(e))}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsChange}
          selectableRowDisabled={selectableRowDisabled}
          paginationComponent={CustomMaterialPagination}
        />
      </StyledDataTable>
      {!Data?.length && isLoading === false && (
        <p style={{ textAlign: "center", color: "red" }}>
          {"No data available in table"}
        </p>
      )}
      {!hideButtonWrapper && buttonWrapper}
    </>
  );
}

export default ReactDataTable;