import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import AdminLogo from "../../../assests/images/logo.svg";
// import OrderConfirmation from "../../../assests/images/orderconfirmation.jpg";
// import PaymentConfirmation from "../../../assests/images/paymentconfirmation.jpg";
// import CompletedOrder from "../../../assests/images/completeorder.jpg";
import {
  DefenceContainer,
  StyledImage,
  StyledOrderBlock,
  StyledDetail,
  StyledBlock,
} from "./style";
// import { Link } from "react-router-dom";
import moment from "moment";
import rightArrow from "../../../assets/images/right-arrow.png";
import { GetDefencePackPdf } from "../../../features/order/orderSlice";
import { FaDownload } from "react-icons/fa";
import { fileExists } from "../../../utils/utility";
import { date } from "yup";
import Loader from "../../Loader/Loader";

const DefenceContent = ({ defencePackName }) => {
  const dispatch = useDispatch();
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const [isLoading, setIsLoading] = useState(false);
  const handleDefencePack = (e) => {
    let data = {
      orderId: OrderDetails?.order_id,
      defencePackType: Number(e.target.id),
    };
    setIsLoading(true);
    dispatch(GetDefencePackPdf(data))
      .unwrap()
      .then(async (res) => {
        setIsLoading(false);
        if (res !== null) {
          const blob = new Blob([res], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(blob);
          console.log('fileURL: ', fileURL)
          window.open(fileURL);
        }
      });
  };

  const checkFileExist = (url) => {
    if (typeof url !== "undefined" && url !== null && url !== "") {
      return fileExists(url).then((response) => {
        return response;
      });
    }
  };
  const today = new date();
  const full_name = `${OrderDetails?.first_name ? OrderDetails?.first_name : ""} ${OrderDetails?.last_name ? OrderDetails?.last_name : ""}`;
  return (
    <DefenceContainer>
      <StyledImage>
        <div className="logo-section">

          <img
            src="https://philippines-etd.com/assets/img/logo-img.jpg"
            className="admin-logo"
            height="80"
            width="80"
          />
          <div className="d-flex">
            <h2 className="logo-content">Philippines E-Travel </h2>
          </div>
        </div>
      </StyledImage>
      <p>Electric Doc Services LLC </p>
      <p>1802 N. Alafaya Trail</p>
      <p>Suite 134</p>
      <p>Orlando</p>
      <p>FL, 32826</p>
      <p>United States</p>
      <p>Tel: +1 (407) 955-9631</p>
      <p className="email">inquiries@philippines-etd.com</p>
      <p>EIN: 85-1546748</p>
      <StyledDetail>{moment(today).format("MMMM DD, YYYY")}</StyledDetail>
      <br />
      <StyledDetail>{OrderDetails?.transaction_id} </StyledDetail>
      <br />
      <StyledDetail>Dear {OrderDetails?.merchant_name ? OrderDetails?.merchant_name : ""} </StyledDetail>
      <br />
      <ol>
        The customer was provided with the service that we advertise, and the customer
        received the Philippines e-travel confirmation. Please find enclosed all the details
        regarding our service. This is the process through in which a customer must proceed to
        place an order with us:
      </ol>
      <br />
      <li
      //  style="display: block;margin-left: auto;margin-right: auto;width: 90%;"
      >
        <b>(1.)</b> Prior to submitting the online application for the Philippines e-travel , a customer who visits our website and wants to learn more about our services before proceeding can view the costs of our services as well as the additional benefits we provide for our customers.
        <b>(Page 2)</b>
      </li>
      <li>In fact, we even have a disclaimer on our site which states that (Our service is notconnected to or affiliated with the Philippines Government), so the
        customer is aware of the fact that we are a third-party service provider. (Page 2).</li>
      <br />
      <li>
        <b>(2.)</b>When the customer has completed and paid for the online application, we are then able to see the{" "}
        <b>Order ID, Date and Time, IP address, Time Zone location,</b> as well
        as the <b>signature</b>. With all of this information, we are then able
        to confirm how many times the application was downloaded.
        <b>( Page 3,4,5 and 6 )</b>
      </li>
      <br />
      <li>
        We pass all customer information like<b> Device IP address, email, phone
          numbers,</b> and{" "}
        <b>addresses</b> to Payees, if these were incorrect or suspected fraud these
        payments would be declined.
      </li>
      <br />
      <StyledBlock>
        NEITHER A REFUND NOR A COMPLAINT HAS BEEN SUBMITTED BY THIS
        CUSTOMER, SO THIS FIRST CHARGEBACK SHOULD BE DENIED ON THE BASIS
        THAT NO REFUND HAS BEEN REQUESTED.
      </StyledBlock>
      <br />
      <span>Fees</span> (Before apply page)
      <br />
      Fee page with links to our additional benefits and Government site.
      <p></p>
      <br />
      <img
        src="https://philippines-etd.com/assets/images/fee_page.png"
        alt="img"
      />
      <br /> <br />
      <b>
        <span>Disclaimer</span>
      </b>{" "}
      (Before apply page)
      <br />
      Homepage with various contact options links and disclaimer.
      <p></p>
      <br />
      <img
        src="https://philippines-etd.com/assets/images/disclaimer1.png"
        alt="img"
      />
      <br /> <br />
      <b>
        <span>Payment Confirmation</span>
      </b>{" "}
      (After completion)
      <br />
      Payment confirmation page.
      <p></p>
      <br />
      <img
        src="https://philippines-etd.com/assets/images/thankyou_page.png"
        alt="img"
      />
      <br /> <br />
      <b>
        <span>Order Confirmation</span>
      </b>
      <br />
      In the confirmation email that is sent to the customer after the order has been paid for, there
      is a link to our terms and conditions. At this stage the customer is still able to contact us if
      they have any questions, concerns or require a refund.
      <br />
      <p></p>
      <br />
      <img
        src="https://philippines-etd.com/assets/images/order_confirmation_mail.png"
        alt="img"
      />
      <br /> <br />
      <b>
        <span>Completed Order Confirmation</span>
      </b>
      <br />
      Once the Philippines e-travel application application has been approved, we email the customer a link
      to our <b>portal</b>{" "}
      where they can download their approved Philippines e-travel QR code and be ready to
      travel to Philippines by air.
      <p></p>
      <br />
      <img
        src="https://philippines-etd.com/assets/images/complete_order_confirmation_mail.png"
        alt="img"
      />
      <br /> <br />
      <b>
        <span>Completed Order</span>
      </b>
      <br />
      In addition to emailing the customer an <b>E-travel</b> approval in a{" "}
      <b>QR code</b> format, we track how many times it has been downloaded.
      <br />
      As the customer accepts our terms and conditions, we can view the digital
      signature the customer has entered. Sometimes the signature is not perfect
      due to the device being used, but we can verify that we have the{" "}
      <b>date, time, IP address,</b> and <b>location</b> of the customer.
      <p></p>
      <br />
      <div
      // style="border:black; padding:15px; border-width:3px; border-style:solid;"
      >
        <StyledBlock>
          {defencePackName !== "defencePack1" ? (
            <StyledOrderBlock>
              <h2>Download History</h2>
              <div className="table-block table-responsive">
                <table width="100%">
                  <thead>
                    <tr>
                      <th width="5%">#</th>
                      <th width="10%">Order ID</th>
                      <th width="22%">Date & Time</th>
                      <th width="23%">Downloaded File</th>
                      <th width="20%">IP Address</th>
                      <th width="20%">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.downloadHistory ? (
                      OrderDetails?.downloadHistory?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.order_id}</td>
                            <td>
                              {moment(item?.create_ts).format(
                                "MM-DD-YYYY h:mm:ss"
                              )}{" "}
                              EST
                            </td>
                            <td>
                              <a
                                href={
                                  OrderDetails?.length !== 0
                                    ? checkFileExist(
                                      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.order_id}_${OrderDetails?.uploadDoc[0]?.file1}`
                                    )
                                      ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.order_id}_${OrderDetails?.uploadDoc[0]?.file1}`
                                      : `https://philippines-etd.com/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : "#"
                                }
                                // href="#"
                                className="download-blue-btn"
                                download
                                rel="noreferrer"
                              >
                                Download File{" "}
                                <span>
                                  <FaDownload className="download-icons" />
                                </span>
                              </a>
                            </td>
                            <td>{item?.ip}</td>
                            <td>{item?.timezone}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" align="center">
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          ) : (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "15%" }}>Order ID</th>
                      <th style={{ width: "40%" }}>Download</th>
                      <th style={{ width: "25%" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">{item?.order_id}</td>
                          <td>
                            {OrderDetails?.uploadDoc &&
                              (
                                <a
                                  href={
                                    OrderDetails !== null
                                      ? checkFileExist(
                                        `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.order_id}_${OrderDetails?.uploadDoc[index]?.file1}`
                                      )
                                        ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.order_id}_${OrderDetails?.uploadDoc[index]?.file1}`
                                        : `https://philippines-etd.com/uploads/${OrderDetails?.uploadDoc[index]?.file1}`
                                      : null
                                  }
                                  className="blue-btn"
                                  download
                                  rel="noreferrer"
                                >
                                  Download File -
                                  <span>{OrderDetails?.uploadDoc[index]?.customer_name}</span>
                                </a>
                              )
                            }
                            {/* {OrderDetails?.uploadDoc?.map((data, i) => {
                              return (
                                <a
                                  href={
                                    OrderDetails !== null
                                      ? checkFileExist(
                                        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.order_id}_${data?.file1}`
                                      )
                                        ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.order_id}_${data?.file1}`
                                        : `https://philippines-etd.com/uploads/${data?.file1}`
                                      : null
                                  }
                                  className="blue-btn"
                                  download
                                  rel="noreferrer"
                                >
                                  Download File -
                                  <span>{data?.customer_name}</span>
                                </a>
                              );
                            })} */}
                          </td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}
        </StyledBlock>
      </div>

      <br />
      <br />
      <b>
        <span>
          Pictured below<span></span>
        </span>
      </b>{" "}
      This is the approved <b>Philippines e-travel</b> in <b>QR</b> format, which the customer
      downloaded from our portal.
      <p></p>
      <br />
      <div
      // style="border:black; padding:15px; border-width:3px; border-style:solid;"
      >
        <embed
          width="200"
          height="150"
          title="pdf"
          src={
            OrderDetails?.length !== 0
              ? checkFileExist(
                `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.order_id}_${OrderDetails?.uploadDoc[0]?.file1 ? (OrderDetails?.uploadDoc[0]?.file1).replace(/[()]/g, "") : ""}}`
              )
                ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.order_id}_${OrderDetails?.uploadDoc[0]?.file1.replace(/[()]/g, "")}`
                : `https://philippines-etd.com/uploads/${(OrderDetails?.uploadDoc[0]?.file1).replace(/[()]/g, "")}`
              : null
          }
        />
      </div>
      {defencePackName !== "defencePack1" ? (
        <button type="button" onClick={handleDefencePack} id="1" disabled={isLoading}>
          {isLoading ? (
            <Loader />
          ) : <> Print Defence Pack 1{" "}
            <span>
              <img src={rightArrow} alt="rightArrow" />
            </span></>
          }

        </button>
      ) : (
        <button type="button" onClick={handleDefencePack} id="2" disabled={isLoading}>
          {isLoading ? (
            <Loader />
          ) : <> Print Defence Pack 2{" "}
            <span>
              <img src={rightArrow} alt="rightArrow" />
            </span></>
          }
        </button>
      )}
    </DefenceContainer>
  );
};

export default DefenceContent;
