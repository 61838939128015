import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../features/order/orderSlice";
import ReactDataTable from "../../../components/ReactDataTable";
import { toast } from "react-toastify";
import moment from "moment";
import { encryptVal } from "../../../utils/utility";
import { jwtDecode } from "jwt-decode";

const NewOrders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const newOrdersList = useSelector((state) => state?.order?.ordersList);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const token = localStorage.getItem("user");
    let userType;
    if (token !== null) {
      const decodedToken = jwtDecode(token);
      console.log(decodedToken);
      userType = decodedToken?.user?.type;
    }

    const handleShow = (orderId) => {
        setShow(true);
        setSelectedData([orderId]);
    };

    useEffect(() => {
        dispatch(getOrdersList({
            orderName: "newOrder",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "Phi- ETD";
    }, [dispatch, navigate, page, limit]);

    const columns = [
        {
          name: "Order Id",
          width: "10rem",
          selector: (row) => row.id,
          sortable: true,
        },
        {
          name: "Name",
          width: "17rem",
          selector: (row) => row.name,
          sortable: true,
        },
        {
          name: "Email",
          width: "15rem",
          selector: (row) => row.email,
          sortable: true,
        },
        {
          name: "Telephone",
          width: "10rem",
          selector: (row) => row.telephone,
          sortable: true,
        },
        {
          name: "Date & Time",
          width: "12rem",
          selector: (row) => row.date
            ? moment(new Date(row.date)).utc().format("MM-DD-YYYY hh:mm:ss")
            : "",
          sortable: true,
        },
        {
            name: "Departure Date",
            width: "12rem",
            selector: (row) => row.departureDate
              ? moment(new Date(row.departureDate)).utc().format("MM-DD-YYYY")
              : "",
            sortable: true,
        },
        {
          name: "Status",
          width: "6rem",
          selector: (row) => row.status,
          sortable: true,
        },
        {
          name: "Action",
          width: "15rem",
          selector: (row) =>
          newOrdersList?.length ? (
              <span>
                <Link
                  to={`/admin/view-order/${encryptVal(row?.id)}`}
                  style={{
                    border: "1px solid #18f",
                    borderRadius: "5px",
                    padding: " 0 5px",
                    minWidth: "80px",
                    display: "inline-block",
                    textAlign: "center",
                    height: "40px",
                    lineHeight: "40px",
                    color: "#111",
                    margin: "5px",
                    textDecoration: "none",
                  }}
                  className="blue-border"
                >
                  View
                </Link>{" "}
                {userType === "Manager" || userType === "Admin" ? (
                  <Link
                    to="#"
                    onClick={() =>
                      handleShow({ id: row?.id, status: row?.status })
                    }
                    style={{
                      textDecoration: "none",
                      border: "1px solid #e92a11",
                      borderRadius: "5px",
                      padding: " 0 5px",
                      minWidth: "80px",
                      display: "inline-block",
                      textAlign: "center",
                      height: "40px",
                      lineHeight: "40px",
                      color: " #e92a11",
                      margin: "5px",
                    }}
                  >
                    Delete
                  </Link>
                ) : null}
              </span>
            ) : (
              " "
            ),
        },
    ];

    const tableData = newOrdersList;

    const handleClick = () => {
      window.location.reload();
  };
    return (
        <>
            <StyledContainer>
                <StyledPageTitle>
                    <h1>New Order</h1>
                    <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>

                </StyledPageTitle>
                <form>
                    <StyledOrderBlock>
                        <div className="table-block table-responsive">
                            <ReactDataTable
                                customColumns={columns}
                                data={tableData}
                                setPage={setPage}
                                setLimit={setLimit}
                                orderName="newOrder"
                            />
                        </div>
                    </StyledOrderBlock>
                </form>
            </StyledContainer>
        </>
    );
}

export default NewOrders;